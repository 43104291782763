<template>
  <!-- <dialog-form-list
    :config="config"
    :formData="formData"
    @cancel="cancel"
    @confirm="confirm"
  /> -->
  <base-dialog
    :showClose="false"
    width="500px"
    @cancel="cancel"
    @confirm="confirm"
  >
    <el-row :gutter="10" class="rowBox">
      <el-col :span="5" :offset="4">{{ $t('registerManage.serverName') }}</el-col>
      <el-col :span="12"><el-autocomplete
        class="rowInput"
        size="mini"
        v-model="formData.serverName"
        :fetch-suggestions="findServiceName"
        placeholder="请输入内容"
        @select="handleSelectName"
      ></el-autocomplete></el-col>
    </el-row>
  </base-dialog>
</template>

<script>
export default {
  props: {
    // 当前选中的表格行数据
    tableRow: {
      type: Object,
      default: () => null,
    },
  },
  data() {
    const self = this
    return {
      cmd: this.$store.state.cmd,
      formData: {
        registerId:'',
        serviceProviderId: '',
        serverName:'',
      },
      serviceListData:[], // 查询返回服务商数据
    }
  },
  mounted() {
    this.setDefaultValue()
  },
  methods: {
    // 设置默认值
    setDefaultValue() {
      console.log(this.tableRow)
      this.$nextTick(() => {
        if(this.tableRow.serviceProvider){
          this.formData.serverName = this.tableRow.serviceProvider.name || {}
          // this.formData.serverCode = this.tableRow.serviceProvider.sourceId
          this.formData.registerId = this.tableRow.id + ''
          this.formData.serviceProviderId = this.tableRow.serviceProvider.id
        }
      })
    },
    handleSelect() {

    },
     // 服务商名称
    findServiceName(e,callback) {
      var that = this
      this.formData.serverName = e
      that.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.findByNameOrMark,
        data:{
          name: e,
        }
      }).then((data) => {
         let list = data
        if (list.length == '0') {
          list.push({
            id: '-1',
            value: '无匹配结果'
          })
          callback(list)
        } else {
          list = list.map(item=>{
          return {
            value:`${item.name}`,
            id: `${item.id}`
          }
        })
         callback(list)
        }
        // console.log(data)
        // const targetItem = [{
        //   value: data.name,
        //   id: data.id
        // }]
        // this.serviceListData = this.$util.copyData(targetItem)
        // callback(this.serviceListData)
      }).catch((error)=>{  
        console.log(error)
       })
    },
    handleSelectName(item) {
      console.log(item)
      this.formData.serverCode = item.id + ''
      this.formData.serverName = item.value
      this.formData.serviceProviderId = item.id
      this.formData.registerId = this.tableRow.id
    },
    // 取消
    cancel() {
      this.$emit('closePage', 'cancel')
    },
    // 确定
    confirm() {
      const formData = this.$util.copyData(this.formData)
      console.log(formData)
      this.$ajax({
        url: '/' + this.$util.getLocalStorage('apiprefix') + this.$api.updateServiceProvider,
        data: this.formData,
      }).then(() => {
        // lang:发票信息设置成功
        this.$element.showMsg(
          this.$t('registerManage.setRegisterSuccess'),'success')
          this.$emit('closePage', 'invoice')
          this.$parent.search() 
      })
    },
  },
}
</script>
<style lang="scss" scoped>
.rowBox{
  margin-top: 10px;
  margin-bottom: 20px;
}
.rowInput{
  width: 200px;
}
</style>